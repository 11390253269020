<template>
<v-container class="pa-1 ma-0">
  <v-row class="ma-0 pr-5 pt-8 d-flex align-center">
    <span style="width:10%; font-size:16px;">时间</span>
    <div style="width:90%; border: solid;
    border-width: 1px;
    border-color: #c5c5c5;">
      <date-range-picker style="color: #c5c5c5;" ref="picker" opens="right" :locale-data="{ 

firstDay: 1,

format: 'yyyy-mm-dd',

applyLabel: '确定',

cancelLabel: '取消',

daysOfWeek: ['日', '一', '二', '三', '四', '五', '六'],

monthNames: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']

}" minDate="2018-01-01" maxDate="2050-12-31" :dateRange="dateRange" :ranges="false" @update="updateDate" :append-to-body="true">
      </date-range-picker>
    </div>
  </v-row>

  <v-row class="ma-0 pr-5 pt-2 d-flex align-center justify-start">
    <span style="width:10%; font-size:16px;">负责人</span>

    <div v-if="selObj.tab==1 && selObj.type==1" style="width:90%;" class="d-flex align-center justify-start">
      <v-checkbox v-model="owners[0].checked" label="" class="pr-0" @change=""></v-checkbox>
      <UserAvatar :fullName="owners[0].userName" :name="owners[0].userName.slice(0,1)" width='30px' height='30px' :userId="owners[0].userId" />
      <span class="pl-1">{{owners[0].userName}}</span>
    </div>

    <div v-if="selObj.tab==1 && selObj.type==2" style="width:90%;" class="d-flex justify-start">
      <v-checkbox v-model="allMemberChk" label="全部成员" class="pr-8" @change="onChangeChk1"></v-checkbox>
      <v-checkbox v-model="userSpecChk" label="自定义选择成员" @change="onChangeChk2"></v-checkbox>
    </div>
  </v-row>

  <v-row v-if="(selObj.tab==1 && selObj.type==2 && userSpecChk) || selObj.tab==2" class="ma-0 pr-5 pt-0 d-flex align-center justify-start">
    <span style="width:10%; font-size:16px;"></span>
    <div style="width:90%; flex-wrap: wrap;" class="d-flex align-center justify-start">
      <div v-for="user in owners.slice(0,moreVal)" class="d-flex align-center justify-start">
        <v-checkbox v-model="user.checked" label="" class="pr-0" @change=""></v-checkbox>
        <UserAvatar :fullName="user.userName" :name="user.userName.slice(0,1)" width='30px' height='30px' :userId="user.userId" />
        <span class="pl-1 pr-8">{{user.userName}}</span>
      </div>
      <div v-if="owners.length>moreVal">
        <v-icon @click="moreVal=moreVal+20">mdi-more</v-icon>..{{owners.length-moreVal}}
      </div>
    </div>
  </v-row>

  <v-row class="ma-0 pr-5 pt-0 d-flex align-center justify-start">
    <span style="width:10%; font-size:16px;">状态</span>
    <div style="width:90%;" class="d-flex align-center justify-start">
      <div v-for="it in taskStatusChks" class="d-flex align-center justify-start">
        <v-checkbox v-model="it.checked" label="" class="pr-0"></v-checkbox>
        <span class="pl-0 pr-8">{{it.label}}</span>
      </div>
    </div>
  </v-row>

  <v-row v-if="selObj.tab==1 && selObj.type==2" class="ma-0 pr-5 pt-0 d-flex align-center justify-start">
    <span style="width:10%; font-size:16px;">范围</span>
    <div style="width:90%;" class="d-flex align-center justify-start">
      <div v-for="it in rangeChks" class="d-flex align-center justify-start">
        <v-checkbox v-model="it.checked" label="" class="pr-0"></v-checkbox>
        <span class="pl-0 pr-8">{{it.label}}</span>
      </div>
    </div>
  </v-row>

  <v-row class="ma-0 pr-5 pt-2 d-flex align-center justify-start">
    <v-btn class="mr-5" dark color="primary" @click="onGenerateTable" :disabled="!chkGen">
      生成任务统计表
    </v-btn>

    <v-btn color="primary" dark @click="onGenerateTable1" :disabled="!chkGen">
      统计定量任务
    </v-btn>
    <v-spacer></v-spacer>
  </v-row>

  <v-row class="ma-0 pr-5 pt-2 d-flex align-center justify-start">
    <zk-table :data="this.thisTable" :columns="itemHeader" children-prop="task_tasks" index-text="#" :stripe="false" :border="false" :show-header="true" :show-summary="false" :show-row-hover="true" :show-index="true" :tree-type="true" :is-fold="false" :expand-type="false" :selection-type="false">
      <template slot="colName" scope="scope">
        <span>
          <v-icon @click="showLink(scope.row)" :style="{color:scope.row._level>=3?'blue':'black'}" small>mdi-link</v-icon>
        </span>
      </template>

      <template slot="colStatus" scope="scope">
        <span v-if="scope.row.item_status!=null">
          {{itemStatus[scope.row.item_status]}}
        </span>
        <span v-else>
          -
        </span>
      </template>
      <template slot="colCompletion" scope="scope">
        <span v-if="scope.row.item_progress!=null">
          {{scope.row.item_progress+'%'}}
        </span>
        <span v-if="scope.row.task_progress!=null">
          {{scope.row.task_progress+'%'}}<br />
          {{getExpiredDays(scope.row.task_end_date)}}
        </span>
      </template>
      <template slot="colProgressDescription" scope="scope">
        <span v-if="scope.row.item_recent_pr!=null">
          {{getFormattedDate(scope.row.item_recent_pr.created_at)}} {{scope.row.ob_recent_pr.employeeName}}<br />
          {{scope.row.item_recent_pr.pr_description}}
        </span>
      </template>

      <template slot="colPriority" scope="scope">
        <span v-if="scope.row.task_priority!=null">
          {{taskPriority[scope.row.task_priority-1]}}
        </span>
      </template>

      <template slot="colOwner" scope="scope">
        <span v-if="scope.row.item_owner!=null">
          {{scope.row.item_owner | username}}
        </span>
        <span v-if="scope.row.ms_owner!=null">
          {{scope.row.ms_owner | username}}
        </span>
        <span v-if="scope.row.task_owner!=null">
          {{scope.row.task_owner | username }}
        </span>
      </template>

      <template slot="colStartDate" scope="scope">
        <span v-if="scope.row.item_start_date!=null">
          {{scope.row.item_start_date}}
        </span>
        <span v-if="scope.row.ms_start_date!=null">
          {{scope.row.ms_start_date}}
        </span>
        <span v-if="scope.row.task_start_date!=null">
          {{scope.row.task_start_date}}
        </span>
      </template>
      <template slot="colEndDate" scope="scope">
        <span v-if="scope.row.item_end_date!=null">
          {{scope.row.item_end_date}}
        </span>
        <span v-if="scope.row.ms_end_date!=null">
          {{scope.row.ms_end_date}}
        </span>
        <span v-if="scope.row.task_end_date!=null">
          {{scope.row.task_end_date}}
        </span>
      </template>

    </zk-table>
  </v-row>
  <v-row>
    <TaskEdit ref="taskEditor" />
    <RightObjectEditor ref="objectEditor" />
    <RightEditor ref="krEditor" />
  </v-row>

</v-container>
</template>

<script>
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import {
  mapGetters,
  mapActions
} from 'vuex';
import {
  constant
} from '@/constants/constant.js';
import moment from 'moment';
import {
  Routes
} from '@/constants/routes'

export default {
  props: ['selObj'],
  components: {
    DateRangePicker,
    UserAvatar: () => import('@/components/common/UserAvatar.vue'),
    TaskEdit: () => import('@/components/common/taskEdit/TaskEdit.vue'),
    RightEditor: () => import('@/components/okrgoal/rightInfo/RightEditor.vue'),
    RightObjectEditor: () => import('@/components/okrmap/RightObjectEditor.vue'),
  },
  data() {
    return {
      moreVal: 20,
      allMemberChk: true,
      userSpecChk: false,
      showTaskChk: false,
      objectType: [],
      itemStatus: ['进行中', '已结束'],
      taskStatus: [],
      taskStatusChks: [],
      rangeChks: [],
      taskPriority: [],
      dateRange: {
        startDate: null,
        endDate: null,
      },
      itemHeader: [{
          label: '项目/任务名称',
          prop: 'task_name',
          width: '350px'
        },
        {
          label: '',
          type: 'template',
          template: 'colName',
          width: '30px'
        },
        {
          label: '状态',
          type: 'template',
          template: 'colStatus',
          width: '100px'
        },
        {
          label: '进度',
          type: 'template',
          template: 'colCompletion',
          width: '120px'
        },
        {
          label: '进展说明',
          type: 'template',
          template: 'colProgressDescription',
          width: '150px'
        },
        {
          label: '任务完成率',
          type: 'template',
          template: 'colTaskRate',
          width: '100px'
        },
        {
          label: '优先级',
          type: 'template',
          template: 'colPriority',
          width: '110px'
        },
        {
          label: '负责人',
          type: 'template',
          template: 'colOwner'
        },
        {
          label: '开始时间',
          type: 'template',
          template: 'colStartDate'
        },
        {
          label: '结束时间',
          type: 'template',
          template: 'colEndDate'
        },
      ],

      owners: [{
        userId: 0,
        userName: "",
        checked: true,
      }],
      thisTable: [],
    };
  },
  watch: {
    selObj: {
      handler: function (val) {
        if (val.tab == 1 && val.type == 1) {
          this.owners = [{
            userId: this.authUser.id,
            userName: this.authUser.employeeName,
            checked: true,
          }];
        }
        if (val.tab == 1 && val.type == 2) {
          let xiashu_users = this.users.filter(user => {
            return user.user_superior == this.authUser.id;
          }).map(user => {
            let newUser = {}
            newUser.userId = user.id;
            newUser.userName = user.employeeName;
            newUser.checked = true;
            return newUser;
          });
          this.owners = xiashu_users;
          this.owners.unshift({
            userId: this.authUser.id,
            userName: this.authUser.employeeName,
            checked: true,
          })
        }

        if (val.tab == 2 && (val.type + "").includes(".")) {
          let xiashu_users = this.users.filter(user => {
            return (user.user_dp_serial + "." + user.id + ".").includes(val.type);
          }).map(user => {
            let newUser = {}
            newUser.userId = user.id;
            newUser.userName = user.employeeName;
            newUser.checked = true;
            return newUser;
          });
          this.owners = xiashu_users;
          this.moreVal = 20;
        }
      },
      deep: true
    }
  },
  mounted() {
    // this.getOkrs({})
    this.taskPriority = constant.priority.map(res => {
      return res.text;
    })
    this.objectType = constant.objectType;
    this.taskStatus = constant.taskStatus.map(item => {
      return item.text;
    });
    this.taskStatusChks = constant.taskStatus.map(item => {
      return {
        checked: true,
        label: item.text,
        value: item.value,
      }
    })

    this.rangeChks = [{
      checked: true,
      label: '我负责的'
    }, {
      checked: true,
      label: '我派发的'
    }, {
      checked: true,
      label: '我参与的'
    }]

    this.owners = [{
      userId: this.authUser.id,
      userName: this.authUser.employeeName,
      checked: true,
    }];
    let start = new Date();
    let end = new Date();
    start.setDate(1);
    end.setMonth(end.getMonth() + 1);
    end.setDate(1);
    end.setDate(end.getDate() - 1);
    this.dateRange = {
      startDate: start,
      endDate: end,
    };

    this.getTasksCall({
      task_start_date: start,
      task_end_date: end,
    })

  },
  computed: {
    ...mapGetters("auth", ["authUser"]),
    ...mapGetters('okr', ['okrObj']),
    ...mapGetters("user", ["users"]),
    ...mapGetters('task', ['tasks']),
    ...mapGetters("auth", ["authUser"]),
    checkedCount() {
      return this.owners.filter(res => {
        return res.checked;
      }).length;
    },
    chkGen() {
      return (this.selObj.tab == 1 && this.selObj.type == 1 && this.checkedCount) ||
        (this.selObj.tab == 1 && this.selObj.type == 2 && (this.allMemberChk || (this.userSpecChk && this.checkedCount))) ||
        (this.selObj.tab == 2 && this.checkedCount);
    }
  },
  methods: {
    ...mapActions('task', ['getTasks', 'getTasksCall']),
    ...mapActions("user", ['getTaskUsers']),
    showLink(param) {
      if (!!param.item_id) {
        let routeData = this.$router.resolve({
          path: `${Routes.ITEM_DETAIL_PAGE}?id=${param.item_id}`
        });
        window.open(routeData.href, "_blank");
      }
      if (!!param.ms_id) {
        let routeData = this.$router.resolve({
          path: `${Routes.ITEM_DETAIL_PAGE}?id=${param.ms_parent_item}`
        });
        window.open(routeData.href, "_blank");
      }
      if (!!param.task_id) {
        this.$refs.taskEditor.openTaskEditor(param);
      }
    },
    getFormattedDate(newDate) {
      return moment(newDate).format("YYYY/MM/DD")
    },
    getExpiredDays(stopDate) {
      const _MS_PER_DAY = 1000 * 60 * 60 * 24;
      let s1 = new Date(stopDate);
      let s2 = new Date();
      '过期天'
      let diff = Math.floor((s1 - s2) / _MS_PER_DAY);
      return diff > 0 ? '过期' + diff + '天' : null;
    },
    getCompletionStatus(tasks) {
      let allCnt = tasks.length;
      let completedCnt = 0;
      tasks.forEach(ele => {
        if (ele.kr_completion && ele.kr_completion == 100) {
          completedCnt++;
        }
        if (ele.task_status && (ele.task_status == 1 || ele.task_progress == 100)) {
          completedCnt++;
        }
      });
      return allCnt == 0 ? '' : Math.floor(completedCnt / allCnt * 100.0) + '%(' + completedCnt + '/' + allCnt + ')'
    },
    updateDate(param) {
      this.dateRange = param;
      this.onGenerateTable();
    },
    onChangeChk1() {
      if (this.allMemberChk) {
        this.userSpecChk = false;
      }
      this.owners = this.owners.map(res => {
        res.checked = this.allMemberChk;
        return res;
      })
    },
    onChangeChk2() {
      if (this.userSpecChk) {
        this.allMemberChk = false;

        this.owners = this.owners.map(res => {
          res.checked = true;
          return res;
        })
      }
    },
    ...mapActions("item", ["getItemsCall"]),
    onGenerateTable() {
      return this.getTasksCall({
        task_start_date: this.dateRange.startDate,
        task_end_date: this.dateRange.endDate,
      }).then(taskRes => {
        this.thisTable = taskRes.filter(task => {
          let chk = true;
          chk = chk && this.owners.filter(owner => {
            return owner.checked && owner.userId == task.task_owner;
          }).length;

          chk = chk && this.taskStatusChks.filter(sts => {
            return sts.checked && sts.value == task.task_status;
          }).length;

          return chk;
        })
        return this.thisTable;
      });
    },
    onGenerateTable1() {
      this.onGenerateTable().then(res => {
        this.thisTable = res.filter(task => {
          return task.task_target_price != null;
        })
      });
    },
  }
}
</script>

<style scoped>
.vue-daterange-picker {
  width: 100%;
}
</style>
